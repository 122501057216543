import request from "@/api/config.js";

export function getAllFAQ() {
  return request({
    url: `faq/get-faq/all`,
    method: "get",
  });
}
export function getFAQByCatID(cat_id) {
  return request({
    url: `faq/get-faq/category/${cat_id}`,
    method: "get",
  });
}
export function getFAQCatList() {
  return request({
    url: `faq/get-category/list`,
    method: "get",
  });
}

export function createOrUpdateFAQ(data) {
  return request({
    url: `faq/create-update-faq`,
    method: "post",
    data,
  });
}
export function createOrUpdateCat(data) {
  return request({
    url: `faq/create-update-category`,
    method: "post",
    data,
  });
}

export function removeFAQ(faq_id) {
  return request({
    url: `faq/remove-faq/${faq_id}`,
    method: "post",
  });
}
export function removeCat(cat_id) {
  return request({
    url: `faq/remove-category/${cat_id}`,
    method: "post",
  });
}
