<template>
  <div class="Page-Wrapper">
    <SortingWithFiltersPreset
      class="SFPRestyle"
      title="Администрирование FAQ"
      searchPlaceholder="Поиск по FAQ"
      :searchAttribures="['title', 'desc']"
      :originalArray="FAQ.List"
      :setNewArrayCallback="handleArrayUpdate"
      :sortMethodCallback="handleSort"
      :filtersButtons="FiltersButtons"
      :sortOptions="[{ text: 'Сортировка по умолчанию', val: 'default' }]"
      :filterButtonModeServer="true"
      :callbackForFilterButton="handleFilter"
      :postFiltersDataChanges="handleFilterChanges"
      :setFiltersData="{
        button: Filters.button,
        search: Filters.search,
        sorting: Filters.sorting,
      }"
      :handleRightClickOnButton="handleRightClick"
      :key="'SortingFiltersSearch-' + faqs_hash"
    >
      <template v-slot:content>
        <div class="SearchButtonsWrapper">
          <div class="SearchButtons">
            <span
              class="Offer-Button-Next"
              style="width: fit-content;"
              @click="categoryModalShow(null)"
              >Создать категорию</span
            >
            <span
              class="Offer-Button-Next"
              style="width: fit-content;"
              @click="faqModalShow(null)"
              >Создать вопрос</span
            >
          </div>
          <span class="AwareCatEdit"
            >Для редактирования категории - кликните правой кнопкой мыши по ней
            (кнопка "Все" - не категория)</span
          >
        </div>
      </template>
    </SortingWithFiltersPreset>

    <div class="FAQsList" :key="'FAQsHash-' + faqs_filtered_hash">
      <div
        class="FAQBlock ql-snow"
        v-for="(faq, findx) in FAQ.List_Filtered"
        :key="
          `FAQB-${findx}-${faq.id}-${faq.title}-${faq.cat_id}-${faq.updatedAt}`
        "
        @click="faqModalShow(faq)"
      >
        <span class="FAQBHeader">{{ faq.title }}</span>
        <span class="FAQDesc ql-editor" v-html="faq.desc"></span>
      </div>

      <span v-if="FAQ.List_Filtered.length === 0" class="FAQIsEmpty"
        >Список пуст</span
      >
    </div>

    <!-- Создание/Обновление категории -->
    <NewStyledModal
      class="Styled-Modal"
      :showModal="CategoryModal.show"
      :hideModal="categoryModalClose"
      :key="CategoryModal.show"
    >
      <template v-slot:ModalContent>
        <div class="Offer-Modal" v-if="CategoryModal.data != null">
          <span class="Offer-Modal-Title">
            {{
              CategoryModal.data?.id != null
                ? "Редактирование категории"
                : "Создание категории"
            }}
          </span>

          <div class="Offer-Modal-Content">
            <div class="Input-Floating-Label">
              <input
                :class="{
                  'Input-styled': true,
                  'Input-highligt-error':
                    CategoryModal.data.title === '' ||
                    CategoryModal.data.title.length < 3,
                }"
                type="text"
                placeholder="Введите название новости"
                autocomplete="off"
                maxlength="64"
                v-model="CategoryModal.data.title"
              />
              <span class="Input-Placeholder noselect">Название категории</span>
              <span class="Input-Text-Length noselect">{{
                64 - CategoryModal.data.title.length
              }}</span>
            </div>
          </div>

          <div class="Offer-Modal-Buttons noselect">
            <span class="Offer-Button-Prev" @click="categoryModalClose"
              >Отмена</span
            >
            <span
              v-if="CategoryModal.data?.id != null"
              class="Offer-Button-Prev"
              @click="removeCategory"
              >Удалить</span
            >
            <span
              class="Offer-Button-Next"
              :style="
                CategoryModal.data.title === '' ||
                CategoryModal.data.title.length < 3
                  ? `opacity: 0.5;`
                  : ''
              "
              @click="
                () => {
                  if (
                    CategoryModal.data.title !== '' &&
                    CategoryModal.data.title.length >= 3
                  ) {
                    createCategory();
                  }
                }
              "
              >{{
                CategoryModal.data?.id != null ? "Cохранить" : "Создать"
              }}</span
            >
          </div>
        </div>
      </template>
    </NewStyledModal>

    <!-- Создание/Обновление FAQ -->
    <NewStyledModal
      class="Styled-Modal"
      :showModal="FAQModal.show"
      :hideModal="faqModalClose"
      :key="FAQModal.show"
    >
      <template v-slot:ModalContent>
        <div class="Offer-Modal" v-if="FAQModal.data != null">
          <span class="Offer-Modal-Title">
            {{
              FAQModal.data?.id != null ? "Редактирование FAQ" : "Создание FAQ"
            }}
          </span>

          <div class="Offer-Modal-Content">
            <div class="Input-Floating-Label">
              <textarea
                :class="{
                  'Input-styled': true,
                  'Input-highligt-error':
                    FAQModal.data.title === '' ||
                    FAQModal.data.title.length < 3,
                }"
                type="text"
                placeholder="Введите заголовок вопроса"
                autocomplete="off"
                maxlength="250"
                v-model="FAQModal.data.title"
              />
              <span class="Input-Placeholder noselect">Заголовок вопроса</span>
              <span class="Input-Text-Length noselect">{{
                250 - FAQModal.data.title.length
              }}</span>
            </div>

            <div class="Input-Floating-Label">
              <div
                class="QEditor QEditorFixHeight"
                style="position: relative; width: 640px; height: 500px;"
                :key="FAQModal.data.title"
              >
                <QuillEditor
                  ref="QuillEditor"
                  toolbar="full"
                  theme="snow"
                  contentType="html"
                  :content="FAQModal.data.desc"
                  @textChange="handleEditorEditChanges($event)"
                  @ready="handleEditorEditReady()"
                />
              </div>
              <span class="Input-Placeholder noselect">Ответ на вопрос</span>
            </div>

            <div class="Input-Floating-Label">
              <select
                v-model="FAQModal.data.cat_id"
                :class="{
                  'Input-styled': true,
                  'Input-highligt-error': FAQModal.data.cat_id == null,
                }"
              >
                <option :value="null">Выберите категорию</option>
                <option
                  v-for="(cat, cindx) in categoriesList"
                  :key="`catInList-${cindx}-${cat.id}`"
                  :value="cat.id"
                  >{{ cat.title }}</option
                >
              </select>
              <span class="Input-Placeholder noselect">Укажите категорию</span>
            </div>
          </div>

          <div class="Offer-Modal-Buttons noselect">
            <span class="Offer-Button-Prev" @click="faqModalClose">Отмена</span>
            <span
              v-if="FAQModal.data?.id != null"
              class="Offer-Button-Prev"
              @click="removeFAQ"
              >Удалить</span
            >
            <span
              class="Offer-Button-Next"
              :style="
                FAQModal.data.title === '' ||
                FAQModal.data.title.length < 3 ||
                FAQModal.data.cat_id == null
                  ? `opacity: 0.5;`
                  : ''
              "
              @click="
                () => {
                  if (
                    FAQModal.data.title !== '' &&
                    FAQModal.data.title.length >= 3 &&
                    FAQModal.data.cat_id != null
                  ) {
                    createFAQ();
                  }
                }
              "
              >{{ FAQModal.data?.id != null ? "Cохранить" : "Создать" }}</span
            >
          </div>
        </div>
      </template>
    </NewStyledModal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  getAllFAQ,
  getFAQByCatID,
  getFAQCatList,
  createOrUpdateFAQ,
  createOrUpdateCat,
  removeFAQ,
  removeCat,
} from "@/api/faq.js";

import { h } from "vue";
import { ElNotification } from "element-plus";

import SortingWithFiltersPreset from "@/components/CustomElements/SortingWithFiltersPreset.vue";
import NewStyledModal from "@/components/CustomElements/NewStyledModal.vue";
import { QuillEditor } from "@vueup/vue-quill";

export default {
  name: "AdminFAQ",
  components: {
    SortingWithFiltersPreset,
    NewStyledModal,
    QuillEditor,
  },
  computed: {
    ...mapGetters({
      user: "auth/isLoggedIn",
    }),
    categoriesList() {
      return this.FiltersButtons.map((x) => {
        if (x.val === null) {
          return null;
        }
        return { id: parseInt(x.val), title: x.text };
      }).filter((x) => x != null);
    },

    faqs_hash() {
      let a = this.FAQ.List.map(
        (x, xindx) => `${xindx}-${x.id}-${x.title}-${x.cat_id}-${x.desc}`
      );
      return `${this.FAQ.List?.length}-${a.join("-")}`;
    },
    faqs_filtered_hash() {
      let a = this.FAQ.List_Filtered.map(
        (x, xindx) => `${xindx}-${x.id}-${x.title}-${x.cat_id}-${x.desc}`
      );
      return `${this.FAQ.List_Filtered?.length}-${a.join("-")}`;
    },
  },
  data() {
    return {
      Filters: {
        button: null,
        search: "",
        sorting: "default",
      },
      FAQ: {
        List: [],
        List_Filtered: [],
      },
      FiltersButtons: [],

      CategoryModal: {
        show: false,
        data: null,
      },
      FAQModal: {
        show: false,
        data: null,
      },
    };
  },
  async mounted() {
    await this.initialization();
  },
  methods: {
    async initialization() {
      let categoriesList_Response = await getFAQCatList();

      let buttonAll = { text: "Все", val: null };
      this.FiltersButtons = [buttonAll];
      if (
        categoriesList_Response.data != null &&
        categoriesList_Response.data.length > 0
      ) {
        this.FiltersButtons = this.FiltersButtons.concat(
          categoriesList_Response.data.map((x) => {
            return { text: x.title, val: x.id };
          })
        );
      }

      if (this.Filters.button === null) {
        let FAQs_Response = await getAllFAQ();
        if (FAQs_Response.data != null && FAQs_Response.data.length > 0) {
          let _faqs_array = FAQs_Response.data.map((cat) => cat.faqs);

          const _faqs_general = _faqs_array.reduce((accumulator, current) => {
            return accumulator.concat(current);
          }, []);

          this.FAQ.List = _faqs_general;
        } else {
          this.FAQ.List = [];
        }
      } else {
        let FAQs_Response = await getFAQByCatID(this.Filters.button);
        if (FAQs_Response.data != null && FAQs_Response.data.length > 0) {
          this.FAQ.List = FAQs_Response.data;
        } else {
          this.FAQ.List = [];
        }
      }
    },

    handleArrayUpdate(UpdatedArray) {
      this.FAQ.List_Filtered = UpdatedArray;
    },
    handleSort(SortName, Array) {
      return Array;
    },
    handleFilter(val) {
      this.Filters.button = val;
      this.initialization();
    },
    handleFilterChanges(obj) {
      this.Filters.button = obj.button;
      this.Filters.search = obj.search;
      this.Filters.sorting = obj.sorting;
    },
    handleRightClick(val) {
      if (val != null) {
        this.categoryModalShow(val);
      }
    },

    categoryModalShow(catID) {
      if (catID == null) {
        this.CategoryModal.data = {
          title: "",
        };
      } else {
        let find = this.FiltersButtons.find((x) => x.val === catID);

        if (find == null) {
          ElNotification({
            title: "Ошибка",
            message: h(
              "b",
              { style: "color: white" },
              "Не удалось найти категорию"
            ),
            position: "top-right",
            customClass: "CutomNotif",
          });
          return 0;
        }

        this.CategoryModal.data = {
          id: parseInt(find.val),
          title: find.text,
        };
      }

      this.CategoryModal.show = true;
    },
    categoryModalClose() {
      this.CategoryModal.show = false;
      this.CategoryModal.data = null;
    },
    async createCategory() {
      await createOrUpdateCat(this.CategoryModal.data);
      this.categoryModalClose();
      await this.initialization();
    },
    async removeCategory() {
      let removeResp = await removeCat(this.CategoryModal.data.id);

      if (removeResp.data.status != "success") {
        ElNotification({
          title: "Ошибка",
          message: h("b", { style: "color: white" }, removeResp.data.msg),
          position: "top-right",
          customClass: "CutomNotif",
        });
      }

      this.categoryModalClose();
      await this.initialization();
    },

    faqModalShow(faqObj) {
      if (faqObj == null) {
        this.FAQModal.data = {
          title: "",
          desc: "",
          cat_id: null,
        };
      } else {
        let copy = JSON.parse(JSON.stringify(faqObj));
        this.FAQModal.data = { ...copy, id: parseInt(copy.id) };
      }

      this.FAQModal.show = true;
    },
    faqModalClose() {
      this.FAQModal.show = false;
      this.FAQModal.data = null;
    },
    handleEditorEditChanges(delta) {
      this.FAQModal.data.desc = this.$refs.QuillEditor.getHTML();
    },
    handleEditorEditReady() {
      this.$refs.QuillEditor.setHTML(this.FAQModal.data.desc);
    },
    async createFAQ() {
      await createOrUpdateFAQ(this.FAQModal.data);
      this.faqModalClose();
      await this.initialization();
    },
    async removeFAQ() {
      await removeFAQ(this.FAQModal.data.id);
      this.faqModalClose();
      await this.initialization();
    },
  },
};
</script>

<style scoped>
.FAQIsEmpty {
  position: relative;
  display: block;

  width: 100%;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 15px;
  color: white;
  text-align: center;
}

.FAQDesc {
  position: relative;

  pointer-events: none;

  padding: 0%;

  width: 100%;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-align: left;

  color: #ffffffe5;
}

.FAQsList {
  position: relative;
  display: flex;

  flex-direction: column;
  justify-content: flex-start;
  gap: 16px;

  width: 100%;
  height: fit-content;
}
.FAQBlock {
  position: relative;
  display: flex;

  cursor: pointer;

  padding: 20px;

  flex-direction: column;
  justify-content: flex-start;
  gap: 24px;

  width: 100%;
  height: fit-content;

  border-radius: 12px;
  background-color: #1e1b4a;
}
.FAQBHeader {
  position: relative;
  display: flex;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;

  color: #ffffffe5;
}

select.Input-styled option {
  background-color: white;
  color: black;
}

.AwareCatEdit {
  position: relative;
  display: block;

  width: 600px;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 14px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.02em;
  text-align: left;
  color: #ffffff;
}
.SearchButtons {
  position: relative;
  display: flex;

  flex-direction: row;
  justify-content: flex-start;
  gap: 8px;

  width: fit-content;
  height: fit-content;
}
.SearchButtonsWrapper {
  position: relative;
  display: flex;

  flex-direction: column;
  justify-content: flex-start;
  gap: 16px;

  width: fit-content;
  height: fit-content;
}

.Page-Wrapper {
  position: relative;
  display: flex;

  margin: 0% auto;
  padding-top: 100px;

  flex-direction: column;
  justify-content: flex-start;
  gap: 44px;

  width: 100%;
  max-width: 1200px;
  min-height: 100svh;

  padding-right: 15px;

  overflow: auto;
}

.NewsRestyle:deep(.NB-Info) {
  display: none;
}
.NewsRestyle {
  cursor: pointer;
}

@media (max-width: 992px) {
  .PageContentWrapper {
    padding-right: 0px;
    padding-bottom: 70px;
  }

  .NewsRestyle:deep(.NB-Desc) {
    -webkit-line-clamp: 4;
  }
  .NewsRestyle:deep(.News-Block) {
    gap: 8px;
  }
  .NewsRestyle:deep(.NB-HeadnDesc) {
    gap: 16px;
  }
}

.Styled-Modal:deep(.SendRequestModal) {
  min-width: 680px;
  width: fit-content;
  background: #1e1b4a;
}
.Offer-Modal {
  position: relative;
  display: flex;

  padding-top: 12px;

  flex-direction: column;
  justify-content: flex-start;
  gap: 24px;

  width: auto;
  height: fit-content;
}

.Offer-Modal-Title {
  position: relative;
  display: block;

  min-width: 320px;
  width: auto;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;

  color: #ffffffe5;
}
.Offer-Modal-Content {
  position: relative;
  display: flex;

  flex-direction: column;
  justify-content: flex-start;
  gap: 16px;

  min-width: 320px;
  width: auto;
  height: fit-content;
}

.Input-Floating-Label {
  position: relative;
  display: block;

  min-width: 320px;
  width: auto;
  height: fit-content;
}

.Input-styled {
  position: relative;
  display: block;

  padding: 12px 8px;

  width: 100%;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-align: left;
  color: #ffffffe5;

  transition-timing-function: ease-in;
  transition: 0.2s;

  background-color: transparent;

  outline: 0;
  outline-offset: 0;

  border-radius: 4px;
  border: 1px solid #d3caff40;
}
textarea.Input-styled {
  min-height: 120px;
}

.Input-styled::placeholder {
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-align: left;
  color: #d3caff73;
}
.Input-styled:focus {
  border-color: #656bff;
}
.Input-styled.Input-highligt-error {
  border-color: #ed254e;
}
.Input-styled.Input-highligt-error:focus + .Input-Placeholder,
.Input-styled.Input-highligt-error + .Input-Placeholder {
  color: #ed254e;
}

.Input-Placeholder {
  position: absolute;
  display: block;

  top: 0;
  left: 18px;

  width: auto;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.01em;
  text-align: left;

  transform: translate(0px, -50%);

  transition-timing-function: ease-in;
  transition: 0.2s;

  color: #d3caff4d;
  background: #1e1b4a;
  padding: 0% 2px;
}
.Input-styled:focus + .Input-Placeholder {
  color: #656bff;
}

.Input-Text-Length {
  position: absolute;
  display: block;

  bottom: 0;
  right: 18px;

  width: auto;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.01em;
  text-align: left;

  transform: translate(0px, 50%);

  transition-timing-function: ease-in;
  transition: 0.2s;

  color: #d3caff4d;
  background: #1e1b4a;
  padding: 0% 2px;
}
.Input-styled:focus + .Input-Text-Length {
  color: #656bff;
}

.Offer-Modal-Buttons {
  position: relative;
  display: flex;

  flex-direction: row;
  justify-content: flex-start;
  gap: 8px;

  width: 320px;
  height: fit-content;
}

.Offer-Button-Next {
  position: relative;
  display: block;
  cursor: pointer;

  padding: 12px 16px;

  width: 100%;
  height: fit-content;

  flex-shrink: 1;

  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  color: #ffffffe5;

  transition-timing-function: ease-in;
  transition: 0.2s;

  border-radius: 4px;
  background-color: #656bff;
}
.Offer-Button-Prev {
  position: relative;
  display: block;
  cursor: pointer;

  padding: 12px 16px;

  width: fit-content;
  height: fit-content;

  flex-shrink: 0;

  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  color: #ffffffe5;

  transition-timing-function: ease-in;
  transition: 0.2s;

  border-radius: 4px;
  background-color: #656bff40;
}
.Offer-Button-Prev:hover {
  background-color: rgba(101, 106, 255, 0.144);
}

.Offer-Text-Before-Confirm {
  position: relative;
  display: block;

  width: 320px;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;

  color: #d3caffb2;
}
.Offer-Text-Before-Confirm .Text-Before-Confirm-Important {
  color: #ffffffe5;
}

.Offer-Modal-Buttons-Child-Wrapper {
  position: relative;
  display: flex;

  flex-direction: column;
  justify-content: flex-start;
  gap: 8px;

  width: 320px;
  height: fit-content;
}

.Offer-Text-Balance-After {
  position: relative;
  display: block;

  width: 320px;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: left;
  text-transform: uppercase;

  color: #d3caff4d;
}
.Offer-Text-Error {
  position: relative;
  display: block;

  margin-top: 12px;

  width: 320px;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: left;
  text-transform: none;
  color: #ed254e;
}
</style>

<style scoped>
.HorizontalBlocks {
  position: relative;
  display: flex;

  flex-direction: row;
  justify-content: flex-start;
  gap: 8px;
}

.checkbox_agreement {
  color: #fff;
  font-family: Montserrat;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  flex-wrap: nowrap;
  margin-top: 0.3rem;
  align-items: center;
}

.checkbox_agreement a {
  font-weight: 500;
  color: #6c59f7;
}

input[type="checkbox"] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  /* Remove most all native input styles */
  appearance: none;
  /* For iOS < 15 */
  background-color: transparent;
  /* Not removed via appearance */
  margin: 0;

  font: inherit;
  color: currentColor;
  width: 1.15em;
  height: 1.15em;
  border: 0.1em solid #6e6a9a;
  border-radius: 4px;
  padding: 0.7rem;
  transform: translateY(-0.075em);

  display: grid;
  place-content: center;

  margin-right: 1rem;
}

input[type="checkbox"]:checked {
  background-image: linear-gradient(221.26deg, #c548ab -3.4%, #635bff 101.08%);
}

input[type="checkbox"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  transform: scale(0);
  transform-origin: bottom left;
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em #fff;

  /* Windows High Contrast Mode */
  background-color: CanvasText;
}

input[type="checkbox"]:checked::before {
  transform: scale(1);
}
</style>

<style scoped>
.dropzone-container {
  position: relative;
  display: flex;

  width: 100%;
  height: auto;

  aspect-ratio: 1 / 0.2;

  background: transparent;
  border: 1px solid;
  border-color: #413e74;

  border-radius: 10px;
}
.hidden-input {
  opacity: 0;
  overflow: hidden;
  position: absolute;
  width: 1px;
  height: 1px;
}

.file-label {
  position: relative;
  display: flex;

  padding: 42px 52px;

  width: 100%;
  height: 100%;

  font-family: "Montserrat";
  font-size: 15px;
  font-weight: 400;
  line-height: 23.25px;
  text-align: center;

  cursor: pointer;

  color: #e8e7ec;
}
.preview-container {
  position: relative;
  display: flex;

  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 10px;

  width: 100%;
  height: fit-content;
}
.preview-card {
  position: relative;
  display: flex;

  flex-direction: column;
  justify-content: center;
  gap: 4px;

  width: calc((100% - 30px) / 4);
  height: fit-content;

  background-color: rgb(48, 46, 81);

  border-radius: 10px;
}

.Preview-Img {
  position: relative;
  display: block;

  width: 100%;
  height: auto;

  aspect-ratio: 1 / 1;

  border-radius: 10px;
}
.FileName {
  position: absolute;
  display: block;

  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  color: aliceblue;
  font-size: 12px;
  text-align: left;
  padding: 8px;
}

.Remove-Button {
  position: absolute;
  display: block;

  cursor: pointer;

  top: 0;
  right: -5px;

  width: 5px;
  height: 5px;

  background: url("./../../assets/img/profile/RemoveButtonMaterials.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
</style>

<style>
.CutomNotif {
  background-color: #302c66;
  border-color: black;
}

.CutomNotif .el-notification__title {
  color: #fd4242;
}
</style>
